
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="ma-3">Send Packed Orders to Shop</h1>
			<v-layout row wrap>
				<!-- from and to -->
				<v-flex xs12 lg4 xl4 md6 sm12>
					<v-text-field class="mx-1" v-model="query.from" label="From" type="date" outlined clearable></v-text-field>
				</v-flex>
				<v-flex xs12 lg4 xl4 md6 sm12>
					<v-text-field class="mx-1" v-model="query.to" label="To" type="date" outlined clearable></v-text-field>
				</v-flex>

				<v-flex xs12 lg4 xl4 md6 sm12>
					<v-autocomplete class="mx-1" v-model="query.customer_username" :items="customers" item-text="customer_username" item-value="customer_username" label="Customer" clearable outlined></v-autocomplete>
				</v-flex>
				<v-flex xs12 lg4 xl4 md6 sm12>
					<!-- status -->
					<v-select class="mx-1" v-model="query.packed_order_status" :items="['delivered', 'returned', 'out', 'ready', 'direct','completed','received in bank']" label="Status" clearable outlined></v-select>
				</v-flex>
				<!-- <v-flex xs12 lg3 xl3 md6 sm12>

					<v-text-field class="mx-1" v-model="query.order_id" label="Order ID" outlined clearable></v-text-field>

				</v-flex> -->
				<v-flex xs12 lg3 xl3 md6 sm12>
					<v-text-field class="mx-1" v-model="query.packed_order_id" label="Packed Order ID" outlined clearable></v-text-field>
				</v-flex>
				<!-- <v-flex xs12 lg3 xl3 md6 sm12>
					<v-autocomplete class="mx-1" v-model="query.user_id" :items="users" item-text="user_username" item-value="user_id" label="Packer" clearable outlined></v-autocomplete>
				</v-flex> -->
				<v-flex xs12 lg2 xl2 md6 sm12>
					<v-btn color="primary" @click="readPackedOrders" class="mx-1" x-large><v-icon>mdi-magnify</v-icon>
						Get</v-btn>
				</v-flex>

			</v-layout>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-title>
							<v-text-field class="mx-1" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined></v-text-field>
						</v-card-title>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="packed_order_id">
								<template v-slot:[`item.packed_order_date`]="{ item }">
									<div>
										{{ new Date(item.packed_order_date).toISOString().split('T')[0]}} {{ new Date(item.packed_order_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.order_date`]="{ item }">
									<div>
										{{ new Date(item.order_date).toISOString().split('T')[0]}} {{ new Date(item.order_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.packed_order_total_price`]="{ item }">
									<div>
										$ {{item.packed_order_total_price}}
									</div>
								</template>
								<template v-slot:[`item.packed_order_total_dinar`]="{ item }">
									<div>
										IQD {{(item.packed_order_total_dinar).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.total_dinar`]="{ item }">
									<div>
										IQD {{(item.total_dinar).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.packed_order_delivery_price`]="{ item }">
									<div>
										IQD {{(item.packed_order_delivery_price).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.order_id`]="{ item }">
									<div>
										{{item.order_id}}/{{item.packed_order_id}}
									</div>
								</template>

								<template v-slot:[`item.packed_order_status`]="{ item }">
									<div>
										<v-chip v-if="item.packed_order_status == 'delivered'" color="gray" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'returned'" color="red" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'out'" color="red" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'ready'" color="green" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'direct'" color="orange" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'completed'" color="gray" dark>{{ item.packed_order_status }}</v-chip>
										<v-chip v-if="item.packed_order_status == 'received in bank'" color="purple" dark>{{ item.packed_order_status }}</v-chip>
									</div>
								</template>
								<template v-slot:[`item.packed_order_id`]="{ item }">
									<div>
										<v-row class="my-0">
											<v-col>
												{{Math.floor((new Date(item.packed_order_date) - new Date(item.order_date)) / (1000 * 60 * 60 * 24))}} days
											</v-col>
											<v-col>
												<v-btn color="primary" @click="selectPackedOrders(item)" class="mx-1" icon><v-icon>mdi-bag-personal</v-icon></v-btn>
											</v-col>
											<!-- edit -->
											<v-col>
											<v-btn color="primary" @click="editPackedOrders(item)" class="mx-1" icon>
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            </v-col>

										</v-row>

									</div>
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deletePackedOrdersList">{{$store.getters.language.data.packed_orders.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
        <v-dialog v-model="showEditDialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    Edit Packed Order
                </v-card-title>
                <v-card-text>
                    <v-autocomplete v-model="bag_edit.packed_order_status" :items="['inshop','delivered', 'returned', 'out', 'ready', 'direct','completed','received in bank']" label="Status" outlined clearable></v-autocomplete>
                
                    <!-- <v-text-field v-model="bag_edit.packed_order_status" label="Status" outlined clearable></v-text-field> -->
                     <v-autocomplete v-model="bag_edit.in_hand_user_id" :items="users" item-text="user_username" item-value="user_id" label="Gave to" clearable outlined></v-autocomplete>

                    <v-text-field v-model="bag_edit.packed_order_note" label="Note" outlined clearable></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="elevation-0" @click="showEditDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="success" class="elevation-0" @click="updatePackedOrders">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.packed_orders.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.packed_orders.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deletePackedOrders(selected_packed_orders.packed_order_id)">
						{{$store.getters.language.data.packed_orders.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showBagDialog" v-if="packed_items.length > 0">
			<v-card>
				<v-card-title>
					Order Bag ( {{$store.getters.language.data.orders.order_date}}: {{ new Date(order.order_date).toISOString().split('T')[0]}} {{ new Date(order.order_date).toISOString().split('T')[1].split('.')[0]}})
					<v-spacer></v-spacer>
					<!-- <v-btn color="success" class="elevation-0" disabled v-if="packed_items.length > 0 && packed_items.every(item => item.order_item_status != 'packed')">
						<v-icon>mdi-bag-carry-on-check</v-icon> Pack bag
					</v-btn>
					<v-btn color="success" class="elevation-0"  @click="addPackedOrders"  v-else>
						Pack bag
					</v-btn> -->

				</v-card-title>
				<v-card-text>
					<!-- {{order}} -->
					<!--  show the order infromations in a card -->
					<v-layout row wrap class="ma-3">
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold">{{$store.getters.language.data.customers.customer_username}}: {{order.customer_username}}</p>
						</v-flex>
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold"> Entered By : {{order.user_username}}</p>
						</v-flex>
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold"> {{$store.getters.language.data.orders.order_shipping_price}}: ${{order.order_shipping_price}}</p>
						</v-flex>
						<v-flex xs12 lg3 xl3 md4 sm5 v-if='order.order_note != null'>
							<p class="font-weight-bold"> {{$store.getters.language.data.orders.order_note}}: {{order.order_note}}</p>
						</v-flex>

						<v-flex xs12 lg3 xl3 md4 sm5>
							<p class="font-weight-bold"> Total Price : ${{order.packed_order_total_price}}</p>
						</v-flex>
						<!-- show paregraph if it was packed otherwise show the button -->
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p v-if='order.order_status == "packed"' style="color:green" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order.order_status}}</p>
							<p v-if='order.order_status == "unpacked"' style="color:red" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order.order_status}}</p>
						</v-flex>

					</v-layout>

					<!-- <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>SKU Code</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Total Price</th>
          <th>Status</th>
          <th>Note</th>
          <th>Order ID</th>
          <th>Order Status</th>
          <th>Order Note</th>
          <th>Date</th>
          <th>User Username</th>
          <th>Order Item Username</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item,index in packed_items" :key="item.order_item_id">
            <td>{{ ++index }}</td>
          <td>{{ item.order_sku_code }}</td>
          <td>$ {{ item.order_item_price }}</td>
          <td>{{ item.order_item_quantity }}</td>
          <td>$ {{ item.order_item_price*item.order_item_quantity }}</td>
          <td>{{ item.order_item_status }}</td>
          <td>{{ item.order_item_note }}</td>
          <td>{{ item.order_id }}</td>
          <td>{{ item.order_status }}</td>
          <td>{{ item.order_note }}</td>
          <td>{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}</td>
          <td>{{ item.user_username }}</td>
          <td>{{ item.order_item_username }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->

					<!-- show the packed_items in a a data table  -->
					<v-data-table :headers="item_headers" :items="packed_items" :loading="loading" class="elevation-1" item-key="order_item_id">
						<template v-slot:[`item.order_item_price`]="{ item }">
							<div>
								$ {{ item.order_item_price }}
							</div>
						</template>
						<template v-slot:[`item.total_item_price`]="{ item }">
							<div>
								$ {{ item.total_item_price }}
							</div>
						</template>
						<template v-slot:[`item.order_item_status`]="{ item }">
							<div>
								<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'postponed'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'unarrived'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>

							</div>
						</template>

						<template v-slot:[`item.order_item_link`]="{ item }">
							<div>
								<a :href="item.order_item_link" target="_blank">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
											<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
										</template>
										<span>{{ item.order_item_link }}</span>
									</v-tooltip>

								</a>
							</div>
						</template>
						<template v-slot:[`item.order_item_date`]="{ item }">
							<div>
								{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
							</div>
						</template>
					</v-data-table>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="showBagDialog = false">
						{{$store.getters.language.data.order_items.cancel_btn}}
					</v-btn>
					<v-btn color="success" class="elevation-0">
						Print
					</v-btn>

				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/packed_orders.request.js'
	import requestsPackedItems from './../../requests/packed_items.request.js'
	export default {
		data() {
			return {
				packed_orders: {},
				packed_items: {},
				order: {},
				query: {},
				search: '',
				loading: false,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
                bag_edit: {},
                showEditDialog: false,
				selected_packed_orders: {},
				delete_dialog: false,
				showBagDialog: false,
				customer: null,
				headers: [


					{
						text: this.$store.getters.language.data.packed_orders.packed_order_date,
						align: 'start',
						sortable: true,
						value: 'packed_order_date',
					},
					{
						text: this.$store.getters.language.data.orders.order_date,
						align: 'start',
						sortable: true,
						value: 'order_date',
					},
					{
						text: this.$store.getters.language.data.packed_orders.packed_order_status,
						align: 'start',
						sortable: true,
						value: 'packed_order_status',
					},
					{
						text: this.$store.getters.language.data.packed_orders.packed_order_total_price,
						align: 'start',
						sortable: true,
						value: 'packed_order_total_price',
					},
					{
						text: "Total Price IQD",
						align: 'start',
						sortable: true,
						value: 'packed_order_total_dinar',
					},
					{
						text: "Delivery Price ",
						align: 'start',
						sortable: true,
						value: 'packed_order_delivery_price',
					},
					{
						text: "Total Price ",
						align: 'start',
						sortable: true,
						value: 'total_dinar',
					},

					// { 
					//     text: this.$store.getters.language.data.packed_orders.packed_order_note,
					//     align: 'start',
					//     sortable: true,
					//     value: 'packed_order_note',
					// },
					// {
					//     text: this.$store.getters.language.data.users.user_id,
					//     align: 'start',
					//     sortable: true,
					//     value: 'user_id',
					// },
					{
						text: 'order/packed_id',
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.packed_orders.packer_username,
						align: 'start',
						sortable: true,
						value: 'packer_username',
					},

					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'packed_order_id',
					}
				],
				item_headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_quantity,
						align: 'start',
						sortable: true,
						value: 'order_item_quantity',
					},
					{
						text: "Total Price",
						align: 'start',
						sortable: true,
						value: 'total_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},

					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},

					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.customers.customer_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'customer_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.actions,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_id',
					// }
				],
			}
		},
		computed: {

			customers() {
				return this.$store.getters.customers_list
			},
			users() {
				return this.$store.getters.users_list
			},

		},
		mounted() {
			// this.readPackedOrders();
		},
		methods: {
            updatePackedOrders() {
                this.loading_btn = true
                console.log(this.bag_edit)
                delete this.bag_edit.total_dinar 
                delete this.bag_edit.order_date
                delete this.bag_edit.order_shipping_price
                delete this.bag_edit.customer_username
                delete this.bag_edit.user_username
                delete this.bag_edit.order_note
                delete this.bag_edit.customer_phone
                delete this.bag_edit.customer_phone_2
                delete this.bag_edit.customer_city
                delete this.bag_edit.customer_address
                delete this.bag_edit.packer_username
                delete this.bag_edit.packed_order_date

                requests.updatePackedOrders(this.bag_edit.packed_order_id
                    ,this.bag_edit).then(r => {
                    if (r.status == 200) {
                        this.showEditDialog = false
                        this.snackbar = {
                            value: true,
                            text: 'PackedOrders Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to update PackedOrders',
                            color: 'error'
                        }
                    }
                })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to update PackedOrders',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })
            },
			addPackedOrders() {
				this.loading_btn = true
				requests.createPackedOrders(this.packed_orders).then(r => {
					if (r.status == 200) {
						this.packed_orders = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'PackedOrders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add PackedOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deletePackedOrders(packed_order_id) {
				requests.deletePackedOrders(packed_order_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.packed_order_id != packed_order_id
						})
						this.snackbar = {
							value: true,
							text: 'PackedOrders Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deletePackedOrdersList() {
				let ids = this.selected_rows.map(m => m.packed_order_id)
				requests.deletePackedOrdersList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.packed_order_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' PackedOrders Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readPackedOrders() {
				this.loading = true
				if (this.query.from && this.query.to) {
					if (new Date(this.query.from) > new Date(this.query.to)) {
						this.snackbar = {
							value: true,
							text: 'From date should be less than To date',
							color: 'error'
						}
						this.loading = false
						return
					}
				}
				if (!this.query.from && !this.query.to) {
					if (!confirm('Are you sure you want to get all the packed orders? There might be a lot of data to load')) {
						this.loading = false
						return
					}
				}

				if (this.query.from && !this.query.to) {
					this.snackbar = {
						value: true,
						text: 'You need to fill both From and To',
						color: 'error'
					}
					this.loading = false
					return
				}
				if (!this.query.from && this.query.to) {
					this.snackbar = {
						value: true,
						text: 'You need to fill both From and To',
						color: 'error'
					}
					this.loading = false
					return
				}
				requests.searchPackedOrders(this.query).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read PackedOrders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read PackedOrders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getAllItems() {
				this.loading = true
				let id = this.selected_packed_orders.packed_order_id
				requestsPackedItems.reportByPackedOrderId(id).then(r => {
					if (r.status == 200) {
						this.packed_items = r.data.rows
						console.log(r.data.rows)
						this.loading = false
						this.showOrders = true
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectPackedOrders(i) {
				this.selected_packed_orders = i
				this.order = i
				this.showBagDialog = true
				this.getAllItems()
			},
            editPackedOrders(i) {
              this.showEditDialog = true
              this.bag_edit = i
            }
		},
	}
</script>
                    